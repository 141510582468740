<template>
  <div id="englishBox" @click.stop="closeItem()">
    <div
      v-if="!animate"
      :class="open ? 'evtop1' : 'evtop'"
      @animationend="animateEnd()"
    >
      <img
        v-if="!downs"
        class="moves"
        src="../assets/move.png"
        @mousedown="mouseDowns()"
      />
      <img
        v-else
        class="downs"
        src="../assets/down.png"
        @mouseup="mouseUps()"
      />
      <img
        v-if="downs"
        class="icon1 icon3"
        src="../assets/icon3.png"
        @mouseup="mouseUps()"
        alt=""
      />
      <div v-else>
        <img
          v-if="iconShow"
          class="icon1"
          src="../assets/icon2.png"
          @mouseout="mouseOvers()"
          @mousedown="mouseDowns()"
          @mouseup="mouseUps()"
        />
        <img
          v-else
          class="icon1"
          src="../assets/icon1.png"
          @mousemove="mouseMoves()"
          @mouseup="mouseUps()"
        />
      </div>
    </div>
    <div v-else class="openBox">
      <div class="tipsBox">
        <div class="tips">
          <img class="logo" src="../assets/logo.png" alt="" />
          <div class="language" v-if="!languages">
            <div @click="showLanguage(0)">
              <img src="../assets/english.png" alt="" />
              English
            </div>
            <!-- <div @click="showLanguage(1)" v-else>
            <img src="../assets/china.png" alt="" />
            Chinese
          </div> -->
          </div>
          <div class="language" v-else>
            <div class="show1" @click="changeLanguage(0)">
              <img src="../assets/english.png" alt="" />
              English
            </div>
            <div class="show" v-show="languages" @click="changeLanguage(1)">
              <img src="../assets/china.png" alt="" />
              中文
            </div>
          </div>
        </div>
      </div>

      <div class="bot">
        <div class="title">We specialize in the Ultimate Art</div>
        <div class="video">
          <el-carousel indicator-position="outside" height="894px">
            <el-carousel-item v-for="(item,index) in swiper" :key="item.id">
              <img class="lbt" :src="item.img" :alt="'轮播图'+index">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="info">
          <div class="infoIcon" @mousemove="iconMouse(0)" @click="clickItem(0)">
            <img
              v-if="mouseKey == '0'"
              class="img2"
              :style="{ 'z-index': aboutKey == 0 ? '10' : '9' }"
              src="../assets/e1.png"
            />
            <img v-else class="img1" src="../assets/e2.png" />
            <div class="brief">
              <img
                v-if="mouseKey == '0'"
                class="gl"
                src="../assets/gl.png"
                alt=""
              />
              <div :class="mouseKey == '0' ? 'tit' : 'tit1'">Who We Are</div>
              <span :class="mouseKey == '0' ? 't' : 't1'"
                >BUSHKA specialize in CG Art Production which is located in
                Chengdu city, P.R. China. BUSHKA focuses on providing the
                ultimate art for the global game and film-television...</span
              >
              <div :class="mouseKey == '0' ? 'tit mt' : 'tit1 mt'">
                Our Core Values
              </div>
            </div>
          </div>
          <div class="infoIcon" @mousemove="iconMouse(1)" @click="clickItem(1)">
            <img
              v-if="mouseKey == '1'"
              class="img2"
              :style="{ 'z-index': aboutKey == 1 ? '10' : '9' }"
              src="../assets/e3.png"
            />

            <img v-else class="img1" src="../assets/e4.png" />
            <div class="brief">
              <img
                v-if="mouseKey == '1'"
                class="gl"
                src="../assets/gl.png"
                alt=""
              />
              <div :class="mouseKey == '1' ? 'tit' : 'tit1'">
                Art Production
              </div>
              <span :class="mouseKey == '1' ? 't' : 't1'"
                >BUSHKA specialize in 3D Art Production in AAA Games and
                Film-television industry, helping our clients to restore the
                real- world......</span
              >
              <div :class="mouseKey == '1' ? 'tit mt' : 'tit1 mt'">
                Game Art Production
              </div>
              <div :class="mouseKey == '1' ? 'tit mtt' : 'tit1 mtt'">
                Game Art Production
              </div>
            </div>
          </div>
          <div class="infoIcon" @mousemove="iconMouse(2)" @click="clickItem(2)">
            <img
              v-if="mouseKey == '2'"
              class="img2"
              :style="{ 'z-index': aboutKey == 2 ? '10' : '9' }"
              src="../assets/e5.png"
            />

            <img v-else class="img1" src="../assets/e6.png" />
            <div class="brief">
              <img
                v-if="mouseKey == '2'"
                class="gl"
                src="../assets/gl.png"
                alt=""
              />
              <div :class="mouseKey == '2' ? 'tit' : 'tit1'">
                Game Character
              </div>
              <div :class="mouseKey == '2' ? 'tit' : 'tit1'">
                Game Environment
              </div>
            </div>
          </div>
          <div class="infoIcon" @mousemove="iconMouse(3)" @click="clickItem(3)">
            <img
              v-if="mouseKey == '3'"
              class="img2"
              :style="{ 'z-index': aboutKey == 3 ? '10' : '9' }"
              src="../assets/e7.png"
            />

            <img v-else class="img1" src="../assets/e8.png" />
            <div class="brief">
              <img
                v-if="mouseKey == '3'"
                class="gl"
                src="../assets/gl.png"
                alt=""
              />
              <div :class="mouseKey == '3' ? 'tit' : 'tit1'">Contact Us</div>
              <span :class="mouseKey == '3' ? 't' : 't1'"
                >Learn more info, please contact:</span
              ><br />
              <span :class="mouseKey == '3' ? 't' : 't1'"
                >estelle.zhong@bushkagame.com </span
              ><br />
              <span :class="mouseKey == '3' ? 't' : 't1'">+86 18108063313</span>
              <br /><br />
              <span :class="mouseKey == '3' ? 't' : 't1'"
                >yinsheng.xia@bushkagame.com</span
              ><br />
              <!-- <span :class="mouseKey == '3' ? 't' : 't1'"></span 
              >-->
              <span :class="mouseKey == '3' ? 't' : 't1'">+86 18708185918</span>
            </div>
          </div>
          <div class="infoIcon" @mousemove="iconMouse(4)" @click="clickItem(4)">
            <img
              v-if="mouseKey == '4'"
              class="img2"
              :style="{ 'z-index': aboutKey == 4 ? '10' : '9' }"
              src="../assets/e9.png"
            />
            <img v-else class="img1" src="../assets/e10.png" />
            <div class="brief">
              <img
                v-if="mouseKey == '4'"
                class="gl"
                src="../assets/gl.png"
                alt=""
              />
              <div :class="mouseKey == '4' ? 'tit' : 'tit1'">Join Us</div>
              <span :class="mouseKey == '4' ? 't' : 't1'"
                >BUSHKA is dedicated to provid- ing efficient and high-quality
                art production services. By joining us...</span
              >
              <div :class="mouseKey == '4' ? 'tit mt' : 'tit1 mt'">
                View All Jobs
              </div>
            </div>
          </div>
          <div
            class="mark"
            :style="{ width: about ? '100%' : '0', opacity: about ? '1' : '0' }"
          ></div>
          <div
            @click.stop="1"
            :class="[about ? 'aboutInfo2' : 'aboutInfo1', changeLeft]"
          >
            <div class="content-group">
              <div class="content-1" v-show="aboutKey === 0 && about">
                <div class="top">
                  <h1 class="title">WHO WE ARE</h1>
                  <p class="p">
                    <span style="font-size: 19px">BUSHKA </span>is dedicated to
                    provide efficient and high-quality art production which is
                    located in Chengdu city, P.R. China.
                  </p>
                  <p class="p">
                    <span style="font-size: 19px">BUSHKA </span>focuses on
                    providing the ultimate art for the global game and
                    film-television industry.
                  </p>
                  <p class="p">
                    Having worked with many independent studios and companies in
                    the United States, Canada and Germany, etc.
                  </p>
                </div>
                <div class="img-group">
                  <viewer>
                    <img
                      class="img-1"
                      src="../assets/xq/about-1.jpg"
                      alt="图片"
                    />
                    <div class="img-content">
                      <img
                        class="img-2"
                        src="../assets/xq/about-2.jpg"
                        alt="图片"
                      />
                      <img
                        class="img-2 l-20"
                        src="../assets/xq/about-3.jpg"
                        alt="图片"
                      />
                    </div>
                    <img
                      class="img-4"
                      src="../assets/xq/about-4.jpg"
                      alt="图片"
                    />
                  </viewer>
                </div>
                <div class="bottom">
                  <h2 class="title">OUR CORE VALUES</h2>
                  <p class="p">
                    <span style="font-size: 19px">BUSHKA</span>is on a mission
                    to provide the ultimate art production with the best ways.
                  </p>
                  <p class="p">
                    Every member of BUSHKA TEAM wants to create art production
                    that exceed our clients’ expectations.
                  </p>
                </div>
                <viewer>
                  <div style="margin: 20px 0">
                    <img
                      class="img-5"
                      src="../assets/xq/about-5.jpeg"
                      alt="图片"
                    />
                    <img
                      class="img-6"
                      src="../assets/xq/about-6.jpg"
                      alt="图片"
                    />
                    <img
                      class="img-7"
                      src="../assets/xq/about-8.jpg"
                      alt="图片"
                    />
                  </div>
                  <img
                    class="img-8"
                    src="../assets/xq/about-7.jpg"
                    alt="图片"
                  />
                </viewer>
              </div>
              <div
                class="content-2"
                style="display: flex"
                v-show="aboutKey === 1 && about"
              >
                <viewer>
                  <img class="img-9" src="../assets/xq/fu-img.png" alt="图片" />
                </viewer>

                <div class="right" style="margin-left: 100px">
                  <h1
                    style="font-size: 28px; width: 1008px; font-weight: normal"
                  >
                    <p>
                      <span
                        style="
                          font-size: 30px;
                          margin-right: 10px;
                          font-weight: bold;
                        "
                        >BUSHKA</span
                      >
                      <span style="font-size: 23px"
                        >specialize in 3D Art Production in AAA Games and
                        Film-television</span
                      >
                    </p>
                    <p style="font-size: 22px">
                      industry,helping our clients to restore the real-world.
                    </p>
                    <p style="font-size: 22px">
                      We have experienced full-time artists who create perfect
                    </p>
                    <p style="font-size: 22px">art for our clients.</p>
                  </h1>
                  <div style="margin-top: 30px">
                    <h2 style="font-size: 26px; margin-bottom: 20px">
                      Game CG Art Production
                    </h2>
                    <p style="font-size: 20px">
                      - 3D modeling and texturing for character/ props/ weapons/
                      vehicle/ environment, etc.
                    </p>
                  </div>
                  <div style="margin-top: 30px">
                    <h2 style="font-size: 26px; margin-bottom: 20px">
                      Film CG Art Production
                    </h2>
                    <p style="font-size: 20px">
                      - Character/ props/ weapons/ vehicle/ poster, etc.
                    </p>
                  </div>
                </div>
              </div>
              <div class="content-center" v-show="aboutKey === 2 && about">
                <div class="group group-1">
                  <i class="title"></i>
                  <viewer>
                    <div class="center-right">
                      <section class="contianer contianer-1">
                        <div
                          class="img-box"
                          :class="'img-box-' + index"
                          v-for="(item, index) in imgArr1"
                          :key="index"
                          @mouseenter="isShowMark = item.id"
                          @mouseleave="isShowMark = -1"
                        >
                          <i
                            class="img"
                            :style="{
                              backgroundImage: `url(${item.small_img_1})`,
                            }"
                            v-show="isShowMark !== item.id"
                          ></i>
                          <i
                            class="img"
                            :style="{
                              backgroundImage: `url(${item.small_img_2})`,
                            }"
                            v-show="isShowMark === item.id"
                          ></i>
                          <img
                            v-if="item.big_img"
                            style="opacity: 0"
                            :src="item.big_img"
                            alt="图片"
                          />
                        </div>
                        <div
                          class="last-box"
                          @mouseenter="isShowMark = 98"
                          @mouseleave="isShowMark = -1"
                        >
                          <i
                            v-show="isShowMark !== 98"
                            class="last-img last-1"
                          ></i>
                          <i
                            v-show="isShowMark === 98"
                            class="last-img last-2"
                          ></i>
                          <img
                            style="opacity: 0"
                            src="../assets/center/big-05.jpg"
                            alt="图片"
                          />
                        </div>
                      </section>
                    </div>
                  </viewer>
                </div>
                <div class="group group-1">
                  <viewer>
                    <div class="center-right">
                      <section class="contianer contianer-1">
                        <div
                          class="first-box"
                          @mouseenter="isShowMark = 100"
                          @mouseleave="isShowMark = -1"
                        >
                          <i
                            v-show="isShowMark !== 100"
                            class="first-img first-1"
                          ></i>
                          <i
                            v-show="isShowMark === 100"
                            class="first-img first-2"
                          ></i>
                          <img
                            style="opacity: 0"
                            src="../assets/center/big-06.jpg"
                            alt="图片"
                          />
                        </div>
                        <div
                          class="img-box clip-2"
                          :class="'clip-box-' + index"
                          v-for="(item, index) in imgArr2"
                          :key="index"
                          @mouseenter="isShowMark = item.id"
                          @mouseleave="isShowMark = -1"
                        >
                          <i
                            class="img"
                            :style="{
                              backgroundImage: `url(${item.small_img_1})`,
                            }"
                            v-show="isShowMark !== item.id"
                          ></i>
                          <i
                            class="img"
                            :style="{
                              backgroundImage: `url(${item.small_img_2})`,
                            }"
                            v-show="isShowMark === item.id"
                          ></i>
                          <img
                            v-if="item.big_img"
                            style="opacity: 0"
                            :src="item.big_img"
                            alt="图片"
                          />
                        </div>
                      </section>
                    </div>
                  </viewer>
                </div>
                <div class="group group-1">
                  <i class="title title-1"></i>
                  <viewer>
                    <div class="center-right">
                      <section class="contianer contianer-1">
                        <div
                          class="img-box"
                          :class="'img-box-' + index"
                          v-for="(item, index) in imgArr3"
                          :key="index"
                          @mouseenter="isShowMark = item.id"
                          @mouseleave="isShowMark = -1"
                        >
                          <i
                            class="img"
                            :style="{
                              backgroundImage: `url(${item.small_img_1})`,
                            }"
                            v-show="isShowMark !== item.id"
                          ></i>
                          <i
                            class="img"
                            :style="{
                              backgroundImage: `url(${item.small_img_2})`,
                            }"
                            v-show="isShowMark === item.id"
                          ></i>
                          <img
                            v-if="item.big_img"
                            style="opacity: 0"
                            :src="item.big_img"
                            alt="图片"
                          />
                        </div>
                        <div
                          class="last-box"
                          @mouseenter="isShowMark = 99"
                          @mouseleave="isShowMark = -1"
                        >
                          <i
                            v-show="isShowMark !== 99"
                            class="last-img last-5"
                          ></i>
                          <i
                            v-show="isShowMark === 99"
                            class="last-img last-6"
                          ></i>
                          <img
                            style="opacity: 0"
                            src="../assets/center/big-15.jpg"
                            alt="图片"
                          />
                        </div>
                      </section>
                    </div>
                  </viewer>
                </div>
                <div class="group group-1">
                  <i class="title title-2"></i>
                  <viewer>
                    <div class="center-right">
                      <section class="contianer contianer-1">
                        <div
                          class="img-box"
                          :class="'img-box-' + index"
                          v-for="(item, index) in imgArr4"
                          :key="index"
                          @mouseenter="isShowMark = item.id"
                          @mouseleave="isShowMark = -1"
                        >
                          <i
                            class="img"
                            :style="{
                              backgroundImage: `url(${item.small_img_1})`,
                            }"
                            v-show="isShowMark !== item.id"
                          ></i>
                          <i
                            class="img"
                            :style="{
                              backgroundImage: `url(${item.small_img_2})`,
                            }"
                            v-show="isShowMark === item.id"
                          ></i>
                          <img
                            v-if="item.big_img"
                            style="opacity: 0"
                            :src="item.big_img"
                            alt="图片"
                          />
                        </div>
                        <div
                          class="last-box-default"
                          @mouseenter="isShowMark = 101"
                          @mouseleave="isShowMark = -1"
                        >
                          <i
                            v-show="isShowMark !== 101"
                            class="last-img last-3"
                          ></i>
                          <i
                            v-show="isShowMark === 101"
                            class="last-img last-4"
                          ></i>
                        </div>
                      </section>
                    </div>
                  </viewer>
                </div>
              </div>
              <div class="content-3" v-show="aboutKey === 3 && about">
                <h1 style="font-size: 30px; margin-bottom: 10px">CONTACT US</h1>
                <p style="font-size: 18px">
                  Learn more about BUSHKA, or request portfolios of our latest
                  work, please fiill our
                </p>
                <p style="font-size: 18px">
                  contact form below and we will be in touch.
                </p>
                <div class="email" @click.stop="1">
                  <p>Name <span style="color: red">*</span></p>
                  <div class="name">
                    <div style="display: flex; flex-direction: column">
                      <input
                        class="ipt"
                        type="text"
                        v-model="form.first_name"
                      />
                      <span>First</span>
                    </div>
                    <div style="display: flex; flex-direction: column">
                      <input class="ipt" type="text" v-model="form.last_name" />
                      <span>Last</span>
                    </div>
                  </div>
                  <p>Email <span style="color: red">*</span></p>
                  <div class="name">
                    <input class="ipt ipt-1" type="text" v-model="form.email" />
                  </div>
                  <p>Company</p>
                  <div class="name">
                    <input
                      class="ipt ipt-1"
                      type="text"
                      v-model="form.company"
                    />
                  </div>
                  <p>Message</p>
                  <div class="name">
                    <input
                      class="ipt ipt-1"
                      type="text"
                      v-model="form.content"
                    />
                  </div>
                  <p>Verification Code <span style="color: red">*</span></p>
                  <div
                    class="name"
                    style="justify-content: flex-start; align-items: center"
                  >
                    <input class="ipt" type="text" v-model="code" />
                    <div
                      style="cursor: pointer; margin-left: 30px"
                      @click.stop="refreshCode"
                    >
                      <yz-code :identifyCode="identifyCode"></yz-code>
                    </div>
                  </div>
                  <img
                    class="loading"
                    src="../assets/loading.gif"
                    alt="loading"
                    v-if="isLoading"
                  />
                  <div v-else class="submit-btn" @click="validateForm()">
                    <span>LET'S TALK</span>
                  </div>
                </div>
              </div>
              <div class="content-4" v-show="aboutKey === 4 && about">
                <div style="margin-bottom: 20px">
                  <h1 style="font-size: 18px">Our Studio Hightlights</h1>
                  <p style="font-size: 18px">
                    We expend our best effort in fostering and nurturing talent
                    new and old, dedicating resources to upgrading their skills
                  </p>
                  <p style="font-size: 18px">
                    and ensuring they remain healthy, happy and motivated.
                    BUSHKA is the perfect place to launch your career in game.
                  </p>
                </div>
                <div style="display: flex; margin-bottom: 50px">
                  <viewer>
                    <img
                      class="join-1"
                      src="../assets/xq/join-1.jpg"
                      alt="图片"
                    />
                    <img
                      class="join-2"
                      src="../assets/xq/join-2.jpg"
                      alt="图片"
                    />
                    <img
                      class="join-3"
                      src="../assets/xq/join-3.jpg"
                      alt="图片"
                    />
                  </viewer>
                </div>
                <div style="margin-bottom: 70px">
                  <h2 style="font-size: 18px">Events</h2>
                  <p style="font-size: 16px">
                    <span style="font-size: 18px">At BUSHKA</span>, we recognize
                    that our people are our greatest asset, and we continuously
                    seek to provide a platform for our team members to
                  </p>
                  <p style="font-size: 18px">
                    progress and develop in experience, skills and
                    responsibilities. We also offer a comprehensive and
                    competitive welfare and career progression
                  </p>
                  <p style="font-size: 18px">
                    system, ensuring that our people are well taken care of and
                    constantly supported.
                  </p>
                </div>
                <div
                  style="display: flex; margin-bottom: 150px; flex-wrap: wrap"
                >
                  <viewer>
                    <img
                      class="join-4"
                      src="../assets/xq/join-4.jpg"
                      alt="图片"
                    />
                    <img
                      class="join-5"
                      src="../assets/xq/join-5.jpg"
                      alt="图片"
                    />
                    <img
                      class="join-6"
                      src="../assets/xq/join-6.jpg"
                      alt="图片"
                    />
                    <img
                      class="join-7"
                      src="../assets/xq/join-7.jpg"
                      alt="图片"
                    />
                  </viewer>
                </div>
                <div class="desc">
                  <div class="desc-left">
                    <section style="margin-bottom: 10px">
                      <p>
                        <span style="font-size: 17px">BUSHKA</span> is dedicated
                        to providing efficient and high-quality art production
                        services.
                      </p>
                      <p>
                        By joining us, you can quickly experience the production
                        and process of first-line games at home and abroad.
                      </p>
                      <p>
                        We can provide you the most suitable jobs, as well as
                        broad development and promotion space and professional
                        training.
                      </p>
                    </section>
                    <section style="margin-bottom: 10px">
                      <h3>3D Environment Artist Trainee</h3>
                      <p>Responsibilities</p>
                      <ol>
                        <li>
                          - This is a 4 month paid Training Program. BUSHKA will
                          arrange the most senior/experience 3D Scene
                        </li>
                        <li>
                          Artists to conduct the training for this program.
                        </li>
                        <li>
                          The trainees successfully completed the courses and
                          pass our final exam will be involved in very high-end
                        </li>
                        <li>next-generation game production projects.</li>
                      </ol>
                      <p>Qualifications</p>
                      <ol>
                        <li>
                          - Fresh graduates in Arts related major (including oil
                          painting, sculpture, print, architecture design, etc.)
                        </li>
                        <li>- Good usage of Computer software.</li>
                        <li>
                          - Good communication skills and teamwork spirit.
                        </li>
                      </ol>
                    </section>
                    <section style="margin-bottom: 10px">
                      <h3>3D Environment Artist</h3>
                      <p>Responsibilities</p>
                      <ol>
                        <li>
                          - Produce model and texture of 3D object、scene in
                          real-time 3D game engine
                        </li>
                        <li>
                          - Understand and follow art requirements and specific
                          needs of the project
                        </li>
                        <li>- Promptly learn any new tools or techniques</li>
                        <li>
                          - Execute tasks assigned to him according to project
                          schedule while meeting quality expectations
                        </li>
                      </ol>
                      <p>Qualifications</p>
                      <ol>
                        <li>
                          -Good command of one of the 3D software usage such as
                          Maya, 3D Max, ZBrush and Substance Painter, etc.
                        </li>
                        <li>
                          - Passionate and motivated to join Game Industry
                        </li>
                        <li>
                          - Good communication skills and spatial thinking
                          skills.
                        </li>
                      </ol>
                    </section>
                  </div>
                  <a
                    class="desc-map"
                    href="http://apis.map.qq.com/tools/poimarker?type=0&marker=coord:30.677887,104.118051;title:成都;addr:四川省成都市成华区建设北路三段32号&key=IUKBZ-D7HW6-RZ6SD-ESIBP-JKNOO-GDBQC&referer=myapp"
                    target="_blank"
                  >
                    <img src="../assets/xq/join-8.png" alt="地图" />
                  </a>
                  <div class="call">
                    <p>Email: zhenzhen.yu@bushkagame.com</p>
                    <p>Phone: +86 135 6891 0075</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert-alert></alert-alert>
  </div>
</template>

<script>
import yzCode from "../components/yzCode.vue";
import md5 from "js-md5";
export default {
  name: "App",
  components: {
    "yz-code": yzCode,
  },
  data() {
    return {
      downs: false,
      iconShow: false,
      open: false,
      animate: false,
      about: false,
      aboutKey: "",
      mouseKey: "",
      languages: false,
      languagesKey: 0,
      identifyCodes: "1234567890AbCDef",
      identifyCode: "",
      key: "AnKsswy26dxs78t28ojsk09e3njs",
      code: "",
      form: {
        language: "en",
        first_name: "",
        last_name: "",
        token: "",
        time: "",
        email: "",
        company: "",
        content: "",
      },
      isLoading: false,
      isShowMark: -1,
      imgArr1: [
        {
          id: 1,
          small_img_1: require("../assets/center/dark-01.png"),
          small_img_2: require("../assets/center/Highlight-01.png"),
          big_img: require("../assets/center/big-01.jpg"),
        },
        {
          id: 2,
          small_img_1: require("../assets/center/dark-02.png"),
          small_img_2: require("../assets/center/Highlight-02.png"),
          big_img: require("../assets/center/big-02.jpg"),
        },
        {
          id: 3,
          small_img_1: require("../assets/center/dark-03.png"),
          small_img_2: require("../assets/center/Highlight-03.png"),
          big_img: require("../assets/center/big-03.jpg"),
        },
        {
          id: 4,
          small_img_1: require("../assets/center/dark-04.png"),
          small_img_2: require("../assets/center/Highlight-04.png"),
          big_img: require("../assets/center/big-04.jpg"),
        },
      ],
      imgArr2: [
        {
          id: 7,
          small_img_1: require("../assets/center/dark-07.png"),
          small_img_2: require("../assets/center/Highlight-07.png"),
          big_img: require("../assets/center/big-07.jpg"),
        },
        {
          id: 8,
          small_img_1: require("../assets/center/dark-08.png"),
          small_img_2: require("../assets/center/Highlight-08.png"),
          big_img: require("../assets/center/big-08.jpg"),
        },
        {
          id: 9,
          small_img_1: require("../assets/center/dark-09.png"),
          small_img_2: require("../assets/center/Highlight-09.png"),
          big_img: require("../assets/center/big-09.jpg"),
        },
        {
          id: 10,
          small_img_1: require("../assets/center/dark-10.png"),
          small_img_2: require("../assets/center/Highlight-10.png"),
          big_img: require("../assets/center/big-10.jpg"),
        },
      ],
      imgArr3: [
        {
          id: 11,
          small_img_1: require("../assets/center/dark-11.png"),
          small_img_2: require("../assets/center/Highlight-11.png"),
          big_img: require("../assets/center/props_01.jpg"),
        },
        {
          id: 12,
          small_img_1: require("../assets/center/dark-12.png"),
          small_img_2: require("../assets/center/Highlight-12.png"),
          big_img: require("../assets/center/big-12.jpg"),
        },
        {
          id: 13,
          small_img_1: require("../assets/center/dark-13.png"),
          small_img_2: require("../assets/center/Highlight-13.png"),
          big_img: require("../assets/center/props_03.jpg"),
        },
        {
          id: 14,
          small_img_1: require("../assets/center/dark-14.png"),
          small_img_2: require("../assets/center/Highlight-14.png"),
          big_img: require("../assets/center/props_04.jpg"),
        },
      ],
      imgArr4: [
        {
          id: 16,
          small_img_1: require("../assets/center/dark-16.png"),
          small_img_2: require("../assets/center/Highlight-16.png"),
          big_img: require("../assets/center/big-16.jpg"),
        },
        {
          id: 17,
          small_img_1: require("../assets/center/dark-17.png"),
          small_img_2: require("../assets/center/Highlight-17.png"),
          big_img: require("../assets/center/big-17.jpg"),
        },
        {
          id: 18,
          small_img_1: require("../assets/center/default-3.png"),
          small_img_2: require("../assets/center/default-3.png"),
        },
        {
          id: 19,
          small_img_1: require("../assets/center/default-3.png"),
          small_img_2: require("../assets/center/default-3.png"),
        },
      ],
      swiper: [{
        id:1,
        img: require('../assets/center/l-1.jpg')
      },{
        id:2,
        img: require('../assets/center/l-2.jpg')
      },{
        id:3,
        img: require('../assets/center/l-3.jpg')
      },{
        id:4,
        img: require('../assets/center/l-4.jpg')
      },{
        id:5,
        img: require('../assets/center/l-5.jpg')
      }]
    };
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  created() {
    if (this.$route.params.id == 1) {
      this.animateEnd();
    }
  },
  computed: {
    changeLeft() {
      if (this.aboutKey === 1 && !this.about) return "cla-5";
      else if (this.aboutKey === 3 && this.about) return "cla-6";
      else return "cla-" + this.aboutKey;
    },
  },
  methods: {
    // 提交表单
    submitForm() {
      const _this = this;
      _this.isLoading = true;
      _this
        .axios({
          method: "post",
          url: "https://www.bushka.com.cn/sendMail",
          data: _this.form,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          _this.isLoading = false;
          if (res.data.code !== 200) alert(res.data.msg);
          else this.tips(res.data.msg);
        })
        .catch((err) => {
          _this.isLoading = false;
          alert(err);
        });
    },

    validateForm() {
      const f = this.form;
      if (!f.first_name.replace(/\s+/g, "").length)
        return this.warning("Please enter first name");
      if (!f.last_name.replace(/\s+/g, "").length)
        return this.warning("Please enter last name");
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(f.email))
        return this.warning("Please enter a legal email address");
      if (this.code.toLowerCase() !== this.identifyCode.toLowerCase())
        return this.warning("Verification code error");
      this.form.time = parseInt(new Date().getTime() / 1000);
      const str = md5((this.key + this.form.time).toString());
      this.form.token = str;
      this.submitForm();
    },

    warning(msg) {
      this.$Alert.alert({
        type: "warning",
        message: msg,
        timeout: 1000,
      });
    },

    tips(msg) {
      this.$Alert.alert({
        type: "success",
        message: msg,
        timeout: 1000,
      });
      // 清空表单
      this.code = "";
      this.makeCode(this.identifyCodes, 4);
      const obj = this.form;
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          if (key !== "language") {
            obj[key] = "";
          }
        }
      }
      setTimeout(() => {
        this.about = false;
      }, 1000);
    },

    // 验证码处理
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    // 刷新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
      // 输出验证码
      // console.log(this.identifyCode);
    },
    mouseDowns() {
      this.downs = true;
    },
    animateEnd() {
      this.animate = true;
    },
    mouseUps() {
      this.downs = false;
      this.open = true;
    },
    mouseMoves() {
      this.iconShow = true;
    },
    mouseOvers() {
      this.iconShow = false;
    },
    clickItem(key) {
      window.event.cancelBubble = true;
      this.aboutKey = key;
      this.about = true;
    },
    closeItem() {
      this.about = false;
      this.languages = false;
    },
    iconMouse(key) {
      if (this.mouseKey == key) return;
      if (this.about) return;
      this.mouseKey = key;
    },
    showLanguage(key) {
      window.event.cancelBubble = true;
      this.languagesKey = key;
      this.languages = true;
    },
    changeLanguage(key) {
      this.languagesKey = key;
      this.languages = false;
      if (key == 0) return;
      this.$router.push({ name: "chinese", params: { id: 1 } });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#englishBox {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: "ltalic";
  position: relative;
}

.evtop {
  width: 100vw;
  height: 350px;
  background-image: url("../assets/entop.png");
  background-position: 20%, top;
  background-repeat: no-repeat;
  background-size: 120% 100%;
  position: absolute;
  /* transform-style: preserve-3d;
  perspective: 500px;
  transform-origin: top; */
}

.evtop1 {
  width: 100vw;
  height: 350px;
  background-image: url("../assets/entop.png");
  background-position: 20%, top;
  background-repeat: no-repeat;
  background-size: 120% 100%;
  position: absolute;
  /* transform-style: preserve-3d;
  perspective: 500px;
  transform-origin: top; */
  animation: evtopOpen 0.5s;
}

@keyframes evtopOpen {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: -350px;
    opacity: 0;
  }
}
.moves {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -190px;
}
.downs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -141px;
}
.icon1 {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -40px;
}
.icon3 {
  width: 140px;
  height: 140px;
  bottom: -10px;
}

.tipsBox {
  width: 100vw;
  height: 60px;
}
.tips {
  width: 100vw;
  height: 60px;
  background-image: url("../assets/blacktip.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  animation: showTips 0.5s;
}

@keyframes showTips {
  0% {
    height: 0;
  }
  100% {
    height: 7vh;
  }
}
.logo {
  position: absolute;
  top: 6px;
  left: 101px;
  height: 43px;
  animation: showLogo 0.6s;
}

@keyframes showLogo {
  0% {
    top: -55px;
  }
  50% {
    top: -55px;
  }
  100% {
    top: 6px;
  }
}

.openBox {
  max-width: 100vw;
  position: relative;
  animation: showOpen 0.5s;
}
@keyframes showOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.language {
  color: #fff;
  position: absolute;
  right: 30px;
  top: 8px;
  animation: showLanguage 0.6s;
  z-index: 99;
}
@keyframes showLanguage {
  0% {
    top: -42px;
  }
  66% {
    top: -42px;
  }
  100% {
    top: 8px;
  }
}

.show1 {
  position: relative;
}

.show {
  animation: show 0.5s;
}

@keyframes show {
  0% {
    margin-top: -52px;
  }
  100% {
    margin-top: 0px;
  }
}

.language div {
  display: flex;
  align-items: center;
  color: #949494;
  background-color: #0c0c0c;
  padding: 10px;
  cursor: pointer;
  width: 153px;
}

.language div img {
  margin-right: 15px;
  font-size: 25px;
}

.bot {
  height: 93vh;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.bot::-webkit-scrollbar {
  width: 0 !important;
}
.bot {
  -ms-overflow-style: none;
}
.bot {
  overflow: -moz-scrollbars-none;
}

.title {
  width: 100%;
  height: 8vh;
  color: #949494;
  text-align: center;
  font-size: 25px;
  line-height: 8vh;
  animation: showTitle 0.6s;
}

@keyframes showTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.video {
  width: 1600px;
  height: 894px;
  background-color: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.info {
  position: absolute;
  top: 1046px;
  left: 50%;
  transform: translateX(-50%);
  width: 1600px;
  height: 800px;
  /* background-image: url("../assets/bg.jpg");  */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  /* overflow: hidden; */
  display: flex;
  justify-content: space-around;
}

.infoIcon {
  width: 310px;
  position: relative;
}

.img1 {
  width: 100%;
  position: relative;
  z-index: 9;
}

.img2 {
  width: 100%;
  position: relative;
  z-index: 10;
}

.gl {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.brief {
  position: absolute;
  top: 156px;
  width: 100%;
  height: 700px;
  box-shadow: 0px 5px 5px 5px;
  padding: 200px 20px 0 20px;
  font-size: 17px;
}
.tit {
  margin-bottom: 20px;
  font-size: 25px;
  overflow: hidden;
  color: #8f8f8f;
  text-overflow: ellipsis;
  margin-top: 15px;
}

.tit1 {
  margin-bottom: 20px;
  font-size: 25px;
  overflow: hidden;
  color: #131313;
  text-overflow: ellipsis;
  margin-top: 15px;
}

.mt {
  position: absolute;
  bottom: 150px;
}
.mtt {
  position: absolute;
  bottom: 100px;
}

.t {
  overflow: hidden;
  color: #8f8f8f;
  line-height: 30px;
}

.t1 {
  overflow: hidden;
  color: #131313;
  line-height: 30px;
}

.aboutInfo1 {
  width: 0;
  height: 702px;
  background-color: #383838;
  position: absolute;
  /* left: unset; */
  bottom: -57px;
  padding: 30px 0px;
  z-index: 9;
  color: #686868;
  transition: width 0.5s ease;
  /* border-top-left-radius: 20px; */
  /* border-top-right-radius: 20px; */
  /* animation: about1 0.5s; */
  margin-top: 888px;
}

.aboutInfo2 {
  width: 1615px;
  height: 702px;
  background-color: #383838;
  position: absolute;
  z-index: 9;
  padding: 30px 5px;
  color: #686868;
  /* left: unset; */
  bottom: -57px;
  transition: width 0.5s ease;
  /* margin-top: -201px; */
  /* border-top-left-radius: 20px; */
  /* border-top-right-radius: 20px; */
  /* animation: about2 0.5s; */
  overflow: hidden;
  /* box-sizing: border-box; */
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  border-radius: 20px;
  border: 2px solid #000;
}
.content-group {
  width: 100%;
  height: 590px;
  background-color: #383838;
  padding-top: 50px;
  color: #686868;
  bottom: 0;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
.content-group img {
  cursor: pointer;
  padding: 5px;
  background-color: white;
  box-sizing: border-box;
}
.mark {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  height: 280px;
  top: -120px;
  width: 100%;
  z-index: 9;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.content-group::-webkit-scrollbar {
  width: 30px;
  /* height: 80px; */
}
.content-group::-webkit-scrollbar-track {
  background-color: #484848;
  /* -webkit-border-top-right-radius: 10px; */
  /* -moz-border-top-right-radius: 10px; */
  /* border-top-right-radius: 10px; */
}
.content-group::-webkit-scrollbar-thumb {
  background-color: #717171;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  border: 2px solid #000;
}

@keyframes about1 {
  0% {
    margin-top: -201px;
  }
  100% {
    margin-top: 450px;
  }
}

@keyframes about2 {
  0% {
    margin-top: 450px;
  }
  100% {
    margin-top: -201px;
  }
}
.cla-0 {
  left: -8px;
  right: unset;
}
.cla-1 {
  animation: about3 0.5s;
}
.cla-2 {
  left: unset;
  right: unset;
}
.cla-3 {
  animation: about5 0.5s;
}
.cla-4 {
  left: unset;
  right: -8px;
}
.cla-5 {
  animation: about4 0.5s;
}
.cla-6 {
  animation: about6 0.5s;
}
@keyframes about3 {
  0% {
    left: 480px;
  }
  100% {
    left: -8px;
  }
}
@keyframes about4 {
  0% {
    left: -8px;
  }
  100% {
    left: 480px;
  }
}
@keyframes about5 {
  0% {
    right: -8px;
  }
  100% {
    right: 480px;
  }
}
@keyframes about6 {
  0% {
    right: 480px;
  }
  100% {
    right: -8px;
  }
}
.content-1 {
  width: 1570px;
  /* height: 645px; */
  padding: 0px 0px 129px 310px;
  box-sizing: border-box;
  /* overflow: hidden; */
}
.content-1 .top {
  margin-bottom: 30px;
}
.content-1 .title {
  /* height: 50px; */
  font-size: 28px;
  text-align: left;
  /* margin-bottom: 15px; */
}
.content-1 .p {
  font-size: 18px;
}
.img-group .img-1 {
  width: 820px;
  margin-bottom: 10px;
  /* height: 486px; */
}
.img-5 {
  width: 500px;
  height: 310px;
  margin-right: 20px;
}
.img-6 {
  width: 250px;
  height: 310px;
  margin-right: 20px;
}
.img-7 {
  width: 250px;
  height: 310px;
  margin-right: 20px;
}
.img-8 {
  width: 600px;
}
.content-2 {
  width: 1570px;
  padding: 60px 0 0 125px;
  box-sizing: border-box;
}
.content-3 {
  width: 1570px;
  padding: 0 0 129px 300px;
  box-sizing: border-box;
}
.img-9 {
  width: 350px;
  background-color: transparent !important;
}
.img-group .img-4 {
  width: 800px;
  margin-bottom: 10px;
}
.img-group .img-content .img-2 {
  width: 520px;
  height: 331px;
  margin-bottom: 10px;
  /* height: 486px; */
}
.l-20 {
  margin-left: 20px;
}
.email {
  width: 700px;
  /* height: 400px; */
  margin-top: 50px;
  /* background-color: white; */
}
.name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  /* flex-direction: column; */
}
.ipt {
  width: 300px;
  height: 30px;
  text-indent: 10px;
  color: #bdbbbb !important;
  border: 1px solid;
  border-radius: 5px;
  border-color: #bdbbbb !important;
  background-color: transparent;
}
.ipt-1 {
  width: 100%;
}
.submit-btn {
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8f8f8f;
  color: white;
  cursor: pointer;
  user-select: none;
}
.submit-btn:active {
  background: #a9a7a7;
}

input:focus {
  outline: medium;
}
.content-4 {
  width: 1570px;
  padding: 50px 0px 139px 50px;
  box-sizing: border-box;
}
.join-1 {
  width: 500px;
  height: 300px;
  margin-right: 40px;
}
.join-2 {
  width: 260px;
  height: 300px;
  margin-right: 40px;
}
.join-3 {
  height: 300px;
}
.join-4 {
  width: 650px;
  margin-right: 40px;
  margin-bottom: 20px;
}
.join-5 {
  height: 365px;
  margin-bottom: 20px;
}
.join-6 {
  height: 300px;
  width: 650px;
  margin-right: 20px;
}
.join-7 {
  height: 300px;
  width: 563px;
}

.desc {
  position: relative;
}
.desc-map {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 10px;
  right: 100px;
}
.desc-map img {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
}
.call {
  position: absolute;
  width: 590px;
  right: -130px;
  bottom: 60px;
}
ol {
  list-style-type: none;
}
.loading {
  padding: 0 !important;
  background-color: transparent !important;
  width: 50px;
  border-radius: 50%;
}
.content-center .group {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  padding: 0 80px;
  overflow: hidden;
}
.content-center .group-1 .title {
  display: inline-block;
  width: 250px;
  height: 40px;
  margin-left: 200px;
  background-size: 100% 100%;
  background-image: url("../assets/center/Character.png");
}
.content-center .group-1 .title-1 {
  width: 150px;
  height: 52px;
  background-image: url("../assets/center/Props.png");
}
.content-center .group-1 .title-2 {
  width: 350px;
  height: 51px;
  background-image: url("../assets/center/Environment.png");
}
.content-center .img-box {
  position: absolute;
  height: 250px;
  width: 302px;
  -webkit-clip-path: polygon(0 0, 100% 0, 36% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 36% 100%, 0% 100%);
  /* background-color: rgb(105, 105, 105); */
  cursor: pointer;
}
.content-center .last-box-default {
  position: absolute;
  left: 505px;
  bottom: -249px;
  height: 230px;
  width: 170px;
  -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
  border-top-right-radius: 5px;
  cursor: pointer;
}
.content-center .last-box-default .last-img {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0;
  background-size: 100% 100%;
  background-color: transparent;
}
.content-center .last-box {
  position: absolute;
  left: 402px;
  bottom: -249px;
  height: 230px;
  width: 275px;
  -webkit-clip-path: polygon(97% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(97% 0, 100% 0, 100% 100%, 0% 100%);
  border-top-right-radius: 5px;
  cursor: pointer;
}
.content-center .first-box {
  position: absolute;
  left: 0px;
  bottom: -249px;
  height: 230px;
  width: 185px;
  /* -webkit-clip-path: polygon(0 0, 100% 100%, 100% 100%, 0% 100%); */
  /* clip-path: polygon(0 0, 100% 100%, 100% 100%, 0% 100%); */
  border-top-left-radius: 5px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
}
.content-center .first-box .first-img,
.content-center .last-box .last-img {
  display: inline-block;
  width: 275px;
  height: 100%;
  padding: 0;
  background-size: 100% 100%;
  background-color: transparent;
}
.last-1 {
  background-image: url("../assets/center/dark-05.png");
}
.last-2 {
  background-image: url("../assets/center/Highlight-05.png");
}
.last-3 {
  background-image: url("../assets/center/default-4.png");
}
.last-4 {
  background-image: url("../assets/center/default-4.png");
}
.last-5 {
  background-image: url("../assets/center/dark-15.png");
}
.last-6 {
  background-image: url("../assets/center/Highlight-15.png");
}
.first-1 {
  background-position: 1px;
  background-image: url("../assets/center/dark-06.png");
}
.first-2 {
  background-position: 1px;
  background-image: url("../assets/center/Highlight-06.png");
}
.content-center .last-box-default img,
.content-center .first-box img,
.content-center .last-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.last-mark {
  position: absolute;
  left: 505px;
  bottom: -249px;
  height: 230px;
  width: 170px;
  -webkit-clip-path: polygon(64% 0, 100% 0, 36% 100%, 0% 100%);
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 0% 100%);
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-top-right-radius: 5px;
}
.first-mark {
  position: absolute;
  left: 505px;
  bottom: -249px;
  height: 230px;
  width: 170px;
  -webkit-clip-path: polygon(0 0, 100% 100%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 100%, 100% 100%, 0% 100%);
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-top-right-radius: 5px;
}
.content-center .img-mark {
  position: absolute;
  height: 250px;
  width: 300px;
  -webkit-clip-path: polygon(64% 0, 100% 0, 36% 100%, 0% 100%);
  clip-path: polygon(64% 0, 100% 0, 36% 100%, 0% 100%);
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.content-center .clip-2 {
  -webkit-clip-path: polygon(0% 0, 100% 0, 100% 100%, 63% 100%);
  clip-path: polygon(0% 0, 100% 0, 100% 100%, 63% 100%);
}
.content-center .clip-2 .img-mark {
  -webkit-clip-path: polygon(0% 0, 36% 0, 100% 100%, 64% 100%);
  clip-path: polygon(0% 0, 36% 0, 100% 100%, 64% 100%);
}
.content-center .img-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content-center .img-box i.img {
  display: inline-block;
  width: 295px;
  height: 100%;
  padding: 0;
  background-size: 100% 100%;
  background-color: transparent;
}
.center-right {
  position: relative;
  width: 700px;
  height: 250px;
}
.center-right .contianer {
  position: relative;
  width: 100%;
  margin-right: 20px;
}
.img-box-0 {
  z-index: 6;
}
.img-box-1 {
  left: 125px;
  z-index: 5;
}
.img-box-2 {
  left: 250px;
  z-index: 4;
}
.img-box-3 {
  left: 375px;
  z-index: 3;
}
.clip-box-0 {
  left: 10px;
  z-index: 3;
}
.clip-box-0 .img {
  background-position-x: 2px;
}
.clip-box-1 {
  left: 135px;
  z-index: 4;
}
.clip-box-2 {
  left: 260px;
  z-index: 5;
}
.clip-box-3 {
  left: 385px;
  z-index: 6;
}
.lbt {
  width: 100%;
  height: 100%;
}
::v-deep .aa-notification {
  max-height: 48px !important;
  min-height: auto !important;
  min-width: 200px !important;
  width: auto;
  text-align: center;
}
::v-deep .el-carousel__arrow {
  display: none !important;
}
</style>
